import React from 'react';

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }

    render() {
        return <div class="carousel slide" id="slider" data-ride="carousel">
            <ol class="carousel-indicators">
                    <li data-target="#slider" data-slide-to="0" class="active"></li>
                    <li data-target="#slider" data-slide-to="1"></li>
                    <li data-target="#slider" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner">

                    <div class="carousel-item">
                       <img src="/img/temp1.jpg" class="d-block w-100" alt="carousel"></img>
                       <div class="container">
                          <div class="carousel-caption text-left">
                             <h1>Example headline.</h1>
                             <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget
                                metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                             <p><a class="btn btn-lg btn-primary" href="#" role="button">Sign up today</a></p>
                          </div>
                       </div>
                    </div>

                    <div class="carousel-item active">
                       <img src="/img/temp2.jpg" class="d-block w-100" alt="carousel"></img>
                       <div class="container">
                          <div class="carousel-caption">
                             <h1>Another example headline.</h1>
                             <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget
                                metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                             <p><a class="btn btn-lg btn-primary" href="#" role="button">Learn more</a></p>
                          </div>
                       </div>
                    </div>

                    <div class="carousel-item">
                       <img src="/img/temp3.jpg" class="d-block w-100" alt="carousel"></img>
                       <div class="container">
                          <div class="carousel-caption text-right">
                             <h1>One more for good measure.</h1>
                             <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget
                                metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                             <p><a class="btn btn-lg btn-primary" href="#" role="button">Browse gallery</a></p>
                          </div>
                       </div>
                    </div>

            </div>

            <a class="carousel-control-prev" href="#slider" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#slider" role="button" data-slide="next">
               <span class="carousel-control-next-icon" aria-hidden="true"></span>
               <span class="sr-only">Next</span>
            </a>

        </div>; 
    }
}

export default Carousel;