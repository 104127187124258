import React from 'react';

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            body: "default body text for card need to dynamically scale",
            tag2: "default tag2"
        };
    }

    // method is called before the render method and can be used to update the state variables of the app, after the constructor has run
    componentDidMount() {
    }

    render() {
        return <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src={this.props.videoLink} title ="embeddedvideo" allowfullscreen></iframe>
                </div>
                <div className="card-body">
                    <p className="card-text">{this.state.body}</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                            <button type="button" className="btn btn-sm btn-outline-secondary">View</button>
                            <button type="button" className="btn btn-sm btn-outline-secondary" onClick={this.goToUsers}>{this.state.tag2}</button>
                        </div>
                        <small className="text-muted">MutedText</small>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Card;