import React from 'react';
import './App.css';
import './main.css';

import Navbar from './components/navbar';
import History from './components/history';
import Main from './components/main';
import Contact from './components/contact';
import Links from './components/links';
import OrderForm from './components/orderform.js';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <div>
      <h1 className='content-main'>UNDER CONSTRUCTION...</h1>
     {/*  <Router>
        <Navbar></Navbar>

        <Switch>
          <Route exact path="/">
            <Main></Main>
          </Route>
          <Route path="/history">
            <History/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route path="/links">
            <Links/>
          </Route>
          <Route path="/orderform">
            <OrderForm/>
          </Route>

          <Route path="/besinsellifler">

          <div className="content-main">

          <div>
                <br></br>
                <h5><b>Besinsel Lifler</b></h5>
                <hr></hr>
                <p>Besinsel lifler, günümüz beslenmesinde hayati önemi olan gıda bileşenleri olarak yerlerini almışlardır. 

Yapılan bilimsel araştırmaların ışığında, her geçen gün faydalarına yenileri eklenmektedir. 

Obezite, diabet, tansiyon, sindirim problemleri gibi modern yaşamın başlıca hastalıklarını engellemek için besinsel lifler tüketmek en iyi çözümlerin başında gelmektedir. 

Besinsel lifler, mide ve bağırsaklardaki enzimler tarafından sindirilemeyen bitkisel maddelerdir. Bu nedenle vucudumuz için direkt enerji kaynağı olamazlar. Lifli gıdaları fazla tüketsek bile kalori alımımız doygunluk oranına göre yüksek olmayacaktır.  

Sağlıklı bir bağırsak florası ve sindirim sistemi için lif tüketimi büyük önem taşıyor. Son yıllarda buna “feel good factor “ deniliyor, yani kendini iyi hissetme faktörü.. 

Sağlıklı bir yaşam sürebilmek için, günlük beslenmemizin içerisinde mutlaka olmak zorundalar. Taze sebze, meyveler, baklagiller tüketerek bunları belli bir oranda alabiliyoruz ancak günümüz yaşam koşullarında her an bu gıdalara erişemeyebiliyoruz.  

Ayrıca günümüzün trendlerinden olan fonksiyonel gıdalar, sağlıklı olmalarının yanısıra lezzetli olmalılar, çabuk hazırlanmalılıar. Tüm bunları besinsel liflerin takviyesi ile sağlayabiliriz.
</p>

            </div> 
          </div>
          
          </Route>

          
        </Switch>

      </Router> */}
    </div>
  );

}

export default App;
