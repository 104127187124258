import React from 'react';
import emailjs from 'emailjs-com';

class OrderForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from_name: '',
            message: '',
        };
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        alert("Your order has been submitted");

        /* emailjs.init("user_XlWydz8u2WVHuxDFB6Fgc"); */
        emailjs.sendForm(
            'service_9q2tyjk',
            'template_jn7d9in',
            event.target,
            'user_XlWydz8u2WVHuxDFB6Fgc'
        )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
          console.log('FAILED...', err);
        });
        event.target.reset();
    }

    render() {
        return <div className="content-main">
            <br></br>
            <h5><b>Order Form</b></h5>
            <hr></hr>
            <form onSubmit={this.mySubmitHandler}>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="NameSurnameField">Name - Surname *</label>
                        <input type="text" class="form-control" id="NameSurnameField" name="from_name"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="CompanyNameField">Company Name *</label>
                        <input type="text" class="form-control" id="CompanyNameField"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="PhoneField">Phone *</label>
                        <input type="text" class="form-control" id="PhoneField" placeholder="Contact Number"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="FaxField">Fax *</label>
                        <input type="text" class="form-control" id="FaxField"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="EmailField">E-mail *</label>
                        <input type="text" class="form-control" id="EmailField"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="GSMField">GSM</label>
                        <input type="text" class="form-control" id="GSMField"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="AddressField">Address</label>
                        <input type="text" class="form-control" id="AddressField"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="CityField">City</label>
                        <input type="text" class="form-control" id="CityField"></input>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="MessageField">Message</label>
                        <textarea class="form-control" id="MessageField" rows="5" placeholder="Type your message here" name="message"></textarea>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary">Send</button>
            </form>
            <br></br>
        </div>;
    }

}

export default OrderForm;