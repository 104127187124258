import React from 'react';

class Featurette extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }

    render() {
        return <div>
            <hr class="featurette-divider featurette-divider-override"></hr>
            <div class="row featurette">
                <div class="col-md-7">
                    <h2 class="featurette-heading">{this.props.title}{/* <span class="text-muted">It’ll blow your mind.</span> */}
                    </h2>
                    <p class="lead">{this.props.text}</p>
                </div>
                <div class="col-md-5">
                    <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="300"
                        xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img"
                        aria-label="Placeholder: 500x500">
                        <title>Placeholder</title>
                        <rect width="100%" height="100%" fill="#eee"></rect><text x="50%" y="50%" fill="#aaa" dy=".3em">500x500</text>
                    </svg>
                </div>
            </div>
        </div>;
    }

}

export default Featurette;