import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";

class ProductInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        };
    }

    render() {
        return <Link class="nav-link header-tabs" to={this.props.toLink}><b>{this.props.linkText}</b></Link>;
    }

}

export default ProductInfo;