import React from 'react';
import {
    layer, control, //name spaces
    Interactions, Controls,     //group
    Map, Layers    //objects
  } from "react-openlayers";

class Contact extends React.Component {

    render() {
        return <div className="content-main">
            <div>
                <br></br>
                <h5><b>Contact</b></h5>
                <hr></hr>
                <h5><b>Arosel Gıda</b> Katki Maddeleri ve Mak. San. Dis Tic. Ltd. Sti.</h5>
                <p><b>Address:</b> Orta Mah. Marifet Sokak No:6 Burak Bora Plaza Ofis no:22 Kartal-İstanbul-Türkiye</p>
                <p><b>Phone:</b> +90 216 457 51 00   Fax: +90 216 457 51 01</p>
                <p><b>E-mail:</b> info@arosel.com.tr</p>    
            </div>

            <div>
                <Map view={{ center: [29.21, 40.91], zoom: 4 }}>
                    <Layers>
                        <layer.Tile />
                        {/* <layer.Vector source={markers} style={markers.style} zIndex="1" /> */}
                    </Layers>
                    <Controls attribution={false} zoom={true}>
                        <control.Rotate />
                        <control.ScaleLine />
                        <control.FullScreen />
                        <control.OverviewMap />
                        <control.ZoomSlider />
                        <control.ZoomToExtent />
                        <control.Zoom />
                    </Controls>
                    <Interactions>
                    </Interactions>
                </Map>
            </div>

        </div>;
    }

}

export default Contact;