import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
  } from "react-router-dom";
import ProductInfo from './productinfo'

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            links: []
        };
    }

    render() {
        return <nav class="navbar navbar-expand-lg header-custom">

            <div class="container-xl">
                
                <a class="navbar-brand" href="#">
                    <img alt="Arosel" src="/img/logo.gif"></img>
                </a>
                <button class="navbar-toggler navbar-dark bg-dark" type="button" data-toggle="collapse" data-target="#navbarsExample07XL"
                    aria-controls="navbarsExample07XL" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse flex-grow-0" id="navbarsExample07XL">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            {/* <a class="nav-link header-tabs" href="#"><b>Home</b><span class="sr-only">(current)</span></a> */}
                            <Link class="nav-link header-tabs" to="/"><b>Anasayfa</b></Link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle header-tabs" href="#" id="dropdown07XL" data-toggle="dropdown"
                                aria-expanded="false"><b>Ürünlerimiz</b></a>
                            <div class="dropdown-menu dropdown-menu-lg-right" aria-labelledby="dropdown07XL">
                                <a class="dropdown-item" href="#"><small>Pektinler</small></a>
                                <a class="dropdown-item" href="#"><small>Besinsel Lifler</small></a>
                                <ProductInfo toLink="/besinsellifler" linkText="Besinsel Lifler"></ProductInfo>
                                <a class="dropdown-item" href="#"><small>Elma ekstraktları</small></a>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle header-tabs" href="#" id="dropdown07XL" data-toggle="dropdown"
                                aria-expanded="false"><b>Uygulamalar</b></a>
                            <div class="dropdown-menu dropdown-menu-lg-right" aria-labelledby="dropdown07XL">
                                {/* <h6 class="dropdown-header"><b>Menu header</b></h6> */}
                                <a class="dropdown-item" href="#"><small>Unlu mamuller</small></a>
                                <a class="dropdown-item" href="#"><small>Et ürünleri</small></a>
                                <a class="dropdown-item" href="#"><small>Reçel-Marmelat</small></a>
                                <a class="dropdown-item" href="#"><small>Meyve suyu-İçecekler</small></a>
                                <a class="dropdown-item" href="#"><small>Şekerleme</small></a>
                                <a class="dropdown-item" href="#"><small>Süt ürünleri</small></a>
                                <a class="dropdown-item" href="#"><small>Dondurma-Sorbe</small></a>
                                <a class="dropdown-item" href="#"><small>Sağlıklı atıştırmalar</small></a>
                                <a class="dropdown-item" href="#"><small>İştah açıcılar</small></a>
                                <a class="dropdown-item" href="#"><small>Gıda takviyeleri</small></a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link header-tabs" to="/links"><b>Markalarımız</b></Link>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle header-tabs" href="#" id="dropdown07XL" data-toggle="dropdown"
                                aria-expanded="false"><b>Kurumsal</b></a>
                            <div class="dropdown-menu dropdown-menu-lg-right" aria-labelledby="dropdown07XL">
                                <Link class="dropdown-item" to="/about"><small>Hakkımızda</small></Link>
                                <Link class="dropdown-item" to="/values"><small>Değerlerimiz</small></Link>
                            </div>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link header-tabs" to="/contact"><b>Bize Ulaşın</b></Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link header-tabs" to="/orderform"><b>Sipariş ver</b></Link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">
                                <img class="header-icon" src="/img/en-icon.png"></img>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </nav>;
    }

}

export default Navbar;