import React from 'react';
import LinkCard from './linkcard';
import Card from './card';

class Links extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className="content-main">
            <br></br>
            <h5><b>Links</b></h5>
            <hr></hr>

            <div class="album py-5 bg-light">
                <div class="container">
                    <div className="row">
                        <LinkCard imgLink="img/img1.gif" imgText="Herbstreith &amp; Fox"></LinkCard>
                        <hr></hr>
                        <LinkCard imgLink="img/img3.gif" imgText="Herbafood"></LinkCard>
                    </div>
                </div>
            </div>

            <br></br>
            <h5><b>Videos</b></h5>
            <hr></hr>
            <div className="content-main">
                <div class="album py-5 bg-light">
                <div class="container">
                    <div className="row">
                        <Card videoLink="https://www.youtube.com/embed/frw70jgNZrE"></Card>
                        <Card videoLink="https://www.youtube.com/embed/IjFs7qEJdMc"></Card>
                        <Card videoLink="https://www.youtube.com/embed/_oP9VuAddSc"></Card>
                    </div>
                </div>
            </div>
            </div>
        </div>;
    }
}

export default Links;