import React from 'react';
import Featurette from './featurette.js';
import Featurette2 from './featurette2.js';
import Scopeitem from './scopeitem.js';
import Carousel from './carousel';


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        return <div>
            <br></br>
            <Carousel></Carousel>
            <div class="content-main">

                <Featurette title="Lifler" text="lorem ipsum"></Featurette>
                <Featurette2 title="Pektinler"></Featurette2>
                <Featurette title="Tatlandırıcılar"></Featurette>

                <hr class="featurette-divider featurette-divider-override"></hr>

                <div class="container marketing">
                    <div class="row">
                        <Scopeitem title="Unlu mamuller"></Scopeitem>
                        <Scopeitem title="Et ürünleri"></Scopeitem>
                        <Scopeitem title="Reçel-Marmelat"></Scopeitem>
                    </div>
                </div>

                <hr class="featurette-divider featurette-divider-override"></hr>

                <div class="container marketing">
                    <div class="row">
                        <Scopeitem title="Meyve suyu-İçecekler"></Scopeitem>
                        <Scopeitem title="Şekerleme"></Scopeitem>
                        <Scopeitem title="Süt ürünleri"></Scopeitem>
                    </div>
                </div>

                <hr class="featurette-divider featurette-divider-override"></hr>

                <div class="container marketing">
                    <div class="row">
                        <Scopeitem title="Dondurma-Sorbe"></Scopeitem>
                        <Scopeitem title="Sağlıklı atıştırmalar"></Scopeitem>
                        <Scopeitem title="İştah açıcılar"></Scopeitem>
                    </div>
                </div>

                <hr class="featurette-divider featurette-divider-override"></hr>

                <div class="container marketing">
                    <div class="row">
                        <Scopeitem title="Gıda takviyeler"></Scopeitem>
                    </div>
                </div>

            </div>
        </div>;
    }
}

export default Main;