import React from 'react';

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div className="content-main">
            <br></br>
            <h5><b>History</b></h5>
            <hr></hr>
            <div className="content-background">
                <p><b className="header-tabs">AROSEL</b> GIDA has been established in 1998 and deals with the presentation and marketing of functional and healthy food additives to Turkish food industry.</p>
                <br></br>
                <p><b className="header-tabs">AROSEL GIDA</b>, recently added pectins, flavours to its product portfolio as an addition to its functional and natural food ingredients. Now we are able to give service to all segments of food industry.</p>
                <br></br>
                <p>Our policy; is to supply the requested goods just in time from our warehouse and giving all technical service to our customers about our products.</p>
                <br></br>
            </div>
        </div>
    }

}

export default History;