import React from 'react';

class LinkCard extends React.Component {

    // method is called before the render method and can be used to update the state variables of the app, after the constructor has run
    componentDidMount() {
    }

    render() {
        return <div class="col-md-4 linkcard-grow">
            <div class="card mb-4 shadow">
                <img src={this.props.imgLink} alt="imglink"></img>
                <div class="card-body">
                    <p class="card-text">{this.props.imgText}</p>
                </div>
            </div>
        </div>;
    }

}

export default LinkCard;